import Vue from 'vue'
import VueRouter from 'vue-router'
import {login, loginZfb} from "../api/login";
import Toast from "vant/lib/toast";

Vue.use(VueRouter)

const routes = [
    // {
    //     path: '/',
    //     redirect: '/home'
    // },
    {
        path: '/home',
        name: 'home',
        component: function () {
            return import('../views/home.vue')
        },
        meta: {
            title: '链路云停',
            keepAlive: false
        }
    },
    {
        path: '/About',
        name: 'About',
        component: function () {
            return import('../views/About.vue')
        }
    },
    {
        path: '/index',
        name: 'Index',
        component: function () {
            return import('../views/index.vue')
        },
        meta: {
            title: '链路云停',
            keepAlive: false
        }
    },
    {
        path: '/temporary',
        name: 'Temporary',
        component: function () {
            return import('../views/temporaryStopPayment.vue')
        },
        meta: {
            title: '临停缴费',
            keepAlive: true
        }
    },
    {
        path: '/coupon',
        name: 'Coupon',
        component: function () {
            return import('../views/couponList.vue')
        },
        meta: {
            title: '优惠券列表',
            keepAlive: false
        }
    },
    {
        path: '/collect',
        name: 'Collect',
        component: function () {
            return import('../views/collectCoupons.vue')
        },
        meta: {
            title: '领券优惠券',
            keepAlive: false
        }
    },
    {
        path: '/admission',
        name: 'Admission',
        component: function () {
            return import('../views/unlicensedCarAdmission.vue')
        },
        meta: {
            title: '入场确认异常呼叫',
            keepAlive: false
        }
    },
    {
        path: '/newly',
        name: 'Newly',
        component: function () {
            return import('../views/newlyAdded.vue')
        },
        meta: {
            title: '绑定月租车',
            keepAlive: true,
        }
    },
    {
        path: '/longRent',
        name: 'LongRent',
        component: function () {
            return import('../views/longRentRenew.vue')
        },
        meta: {
            title: '长租续费',
            keepAlive: false
        }
    },
    {
        path: '/parkingLot',
        name: 'ParkingLot',
        component: function () {
            return import('../views/parkingLotLists.vue')
        },
        meta: {
            title: '绑定月租车',
            keepAlive: false
        }
    },
    {
        path: '/unattended',
        name: 'Unattended',
        component: function () {
            return import('../views/unattended.vue')
        },
        meta: {
            title: '无人值守管理授权',
            keepAlive: false
        }
    },
    {
        path: '/invalidCoupon',
        name: 'InvalidCoupon',
        component: function () {
            return import('../views/invalidCoupon.vue')
        },
        meta: {
            title: '无效优惠券',
            keepAlive: false
        }
    },
    {
        path: '/visitor',
        name: 'visitor',
        component: function () {
            return import('../views/visitor.vue')
        },
        meta: {
            title: '访客申请',
            keepAlive: false
        }
    },
    {
        path: '/registration',
        name: 'registration',
        component: function () {
            return import('../views/visitorRegistration.vue')
        },
        meta: {
            title: '访客登记',
            keepAlive: false
        }
    },
    {
        path: '/visitorRecord',
        name: 'visitorRecord',
        component: function () {
            return import('../views/visitorApplicationRecord.vue')
        },
        meta: {
            title: '访客申请记录',
            keepAlive: false
        }
    },
    //临停支付完成页
    {
        path: '/pay/temp/finish',
        name: 'paymentCompletionPage',
        component: function () {
            return import('../views/paymentCompletionPage.vue')
        },
        meta: {
            title: '支付完成',
            keepAlive: false
        }
    },
    //长租支付完成页
    {
        path: '/pay/long/finish',
        name: 'paymentCompleted',
        component: function () {
            return import('../views/paymentCompleted.vue')
        },
        meta: {
            title: '支付完成',
            keepAlive: false
        }
    },
    {
        path: '/invoice',
        name: 'invoice',
        component: function () {
            return import('../views/invoice.vue')
        },
        meta: {
            title: '开具发票',
            keepAlive: false
        }
    },
    {
        path: '/abnormalCall',
        name: 'abnormalCall',
        component: function () {
            return import('../views/abnormalCall.vue')
        },
        meta: {
            title: '异常呼叫',
            keepAlive: false
        }
    },
    {
        path: '/accessControl',
        name: 'accessControl',
        component: function () {
            return import('../views/accessControl.vue')
        },
        meta: {
            title: '门禁权限申请',
            keepAlive: false
        }
    },
    {
        path: '/ACRegistration',
        name: 'ACRegistration',
        component: function () {
            return import('../views/ACRegistration.vue')
        },
        meta: {
            title: '人脸权限申请',
            keepAlive: false
        }
    },
    {
        path: '/ACRecords',
        name: 'ACRecords',
        component: function () {
            return import('../views/ACRecords.vue')
        },
        meta: {
            title: '申请记录',
            keepAlive: false
        }
    },
    {
        path: '/faceRecognition',
        name: 'faceRecognition',
        component: function () {
            return import('../views/faceRecognition.vue')
        },
        meta: {
            title: '信息登记',
            keepAlive: false
        }
    },
    {
        path: '/locationMap',
        name: 'locationMap',
        component: function () {
            return import('../views/locationMap.vue')
        },
        meta: {
            title: '访客信息',
            keepAlive: false
        }
    },
    {
        path: '/admissionPayment',
        name: 'admissionPayment',
        component: function () {
            return import('../views/admissionPayment.vue')
        },
        meta: {
            title: '入场缴费',
            keepAlive: false
        }
    }
]


const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

function getCode() {
    var url = location.search;
    var code = "";
    if (url.indexOf("?") != -1) {
        var split = url.split("?code=");
        code = split[1].split("&")[0];
    }
    return code;
}

function getAuthCode(code) {
    var currentUrl = window.location.href; //获取当前链接
    var arr = currentUrl.split("?");//分割域名和参数界限
    if (arr.length > 1) {
        arr = arr[1].split("&");//分割参数
        for (var i = 0; i < arr.length; i++) {
            var tem = arr[i].split("="); //分割参数名和参数内容
            if (tem[0] == code) {
                return tem[1];
            }
        }
        return null;
    } else {
        return null;
    }
}

function getUrl(path) {
    var currentUrl = path; //获取当前链接
    var arr = currentUrl.split("?");//分割域名和参数界限
    let uu = arr[0];
    if (arr.length > 1) {
        arr = arr[1].split("&");//分割参数
        console.log(arr)
        arr.shift();
        var a = arr[0].split("%3Fstate%3DSTATE");
        var b = arr[1].split("%3Fstate%3DSTATE");
        return uu + "?" + a[0] + "&" + b[0] + "&" + arr[2];
    }
}

function getToken(next, path, name) {
    console.log(path)
    let redirect_uri = "";
    if (process.env.NODE_ENV === "production") {
        redirect_uri = "https://pc.rlinking.com/#" + path;
    } else if (process.env.NODE_ENV === "test") {
        if (name == 'Temporary' || name == 'Admission' || name == 'abnormalCall') {
            redirect_uri = "https://passage-user-test.rlinking.com/#" + path;
        } else {
            redirect_uri = "http://passage-user-test.rlinking.com/#" + path;
        }
    } else if (process.env.NODE_ENV === "development") {
        redirect_uri = "http://passage-user-test.rlinking.com/#" + path;
        // redirect_uri = "https://passage-user-test.rlinking.com/#" + path;
    }
    let ua = navigator.userAgent.toLowerCase();
    let isWeixin = ua.indexOf('micromessenger') != -1;
    let token = sessionStorage.getItem('token');
    if (!isWeixin) {
        let theAuthCode = getAuthCode("auth_code");
        if (theAuthCode && path != '/locationMap') {
            loginZfb(theAuthCode).then((res) => {
                if (res.data.code == 'SUCCESS') {
                    //成功进这个
                    sessionStorage.setItem('avatar', res.data.data.avatar);
                    sessionStorage.setItem('token', res.data.data.token);
                    sessionStorage.setItem('userId', res.data.data.id);
                    sessionStorage.setItem('userInfo', JSON.stringify(res.data.data));
                    sessionStorage.setItem('payType', 2);
                    //跳转
                    var url = getUrl(redirect_uri);
                    window.location.href = url;
                    window.location.reload();
                } else {
                    alert(res.data.message);
                }
            }).catch(err => { //报错进这个
                Toast("报错信息" + err);
            });
        } else if (!token && path != '/locationMap') {
            let getAuthCodeUrl = 'https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?' +
                'app_id=2021003108614686&scope=auth_user,auth_base&redirect_uri=' + encodeURIComponent(redirect_uri) + '?state=STATE';
            window.location.href = getAuthCodeUrl;
        } else {
            next();
        }
    } else if (isWeixin) {
        let theCode = getCode();
        if (theCode && path != '/locationMap') {
            login(theCode).then((res) => {
                if (res.data.code == 'SUCCESS') {
                    //成功进这个
                    sessionStorage.setItem('avatar', res.data.data.avatar);
                    sessionStorage.setItem('token', res.data.data.token);
                    sessionStorage.setItem('userId', res.data.data.id);
                    sessionStorage.setItem('openId', res.data.data.openId);
                    sessionStorage.setItem('userInfo', JSON.stringify(res.data.data));
                    sessionStorage.setItem('payType', 1);
                    //跳转
                    window.location.href = redirect_uri;
                } else {
                    alert(res.data.message)
                }
            }).catch(err => { //报错进这个
                Toast("报错信息" + err);
            });
        } else if (!token && path != '/locationMap') {
            let url = "https://open.weixin.qq.com/connect/oauth2/authorize"
            url += "?appid=wxa9aa498cc6b6b514"
            url += "&redirect_uri=" + encodeURIComponent(redirect_uri)
            url += "&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect"
            window.location.href = url;
        } else {
            next();
        }
    } else {
        next();
    }
}

router.beforeEach((to, from, next) => {
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
    console.log(to)
    getToken(next, to.fullPath, to.name);

    /* if (to.name != 'About') {//判断当前是不是新建的About路由空白页面
         let token = sessionStorage.getItem('token');
         // let token = 'afebcacd-dc58-42b5-8a34-a48536790e7c';
         // sessionStorage.setItem('token', token);
         // next();
         console.log(to)
         if (!token) {//若是没有token,则让它受权
             window.sessionStorage.setItem("authUrl", to.fullPath);
             console.log()
             let redirect_uri = "";
             if (process.env.NODE_ENV === "production") {
                 redirect_uri = "http://pc.rlinking.com/#/About"
             } else if (process.env.NODE_ENV === "test") {
                 redirect_uri = "http://passage-user-test.rlinking.com/#/About"
             } else if (process.env.NODE_ENV === "development") {
                 redirect_uri = "http://passage-user-test.rlinking.com/#/About"
             }
             //判断是否在微信浏览器
             var ua = navigator.userAgent.toLowerCase();
             var isWeixin = ua.indexOf('micromessenger') != -1;
             if (!isWeixin) {
                 //http : //example.com/doc/toAuthPage.html?app_id=2014101500013658&source=alipay_wallet&scope=auth_user&auth_code=ca34ea491e7146cc87d25fca24c4cD11
                 let getAuthCodeUrl = 'https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?' +
                     'app_id=2021003108614686&scope=auth_user,auth_base&redirect_uri=' + encodeURIComponent(redirect_uri) + '?state=STATE';
                 // alert(getAuthCodeUrl)
                 window.location.href = getAuthCodeUrl;
             } else {
                 let url = "https://open.weixin.qq.com/connect/oauth2/authorize"
                 url += "?appid=wxa9aa498cc6b6b514"
                 url += "&redirect_uri=" + encodeURIComponent(redirect_uri)
                 url += "&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect"
                 window.location.href = url
                 // alert(url)
             }
         } else {
             next();
         }
     } else {
         next();
     }*/
});

export default router
